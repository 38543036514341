import React, { useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Layout from "../../components/layout/Index"
import Title from "../../components/commons/page/title/Index"
import ListArticle from "../../components/content/article/list/Index"
import VerticalAds from "../../components/commons/ads/vertical-ads/Index"
// import Pagination from "../../components/commons/pagination/index"
import { graphql, Link } from "gatsby"
import { withTrans } from "../../i18n/withTrans"
import { inRangeDate } from "../../utils/date"
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Pagination from "@mui/material/Pagination"

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      marginTop: useTheme().spacing(2),
    },
  },
}))


const Artikel = ({ data, t, i18n }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTextTemp, setSearchTextTemp] = useState("")
  const [searchText, setSearchText] = useState("")
  const classes = useStyles()
  const language = i18n.language
  const article = data?.allWpArtikel?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )

  const dataIklan = data?.allWpIklanGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklanGoldB = dataIklan?.filter(
    o => o.iklan.slotIklan === "Gold-B (280 x 450 px)" && o.iklan.letakIklan.goldB === "Artikel"
  )

  const pageSize = 4
  const pagesCount =
    article.filter(item =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    ).length / pageSize
  const roundPagesCount = Math.ceil(pagesCount)
  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  const handlePreviousClick = (e, currentPage) => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextClick = (e, currentPage) => {
    setCurrentPage(currentPage + 1)
  }

  const onSearch = e => {
    e.preventDefault()
    setSearchText(searchTextTemp)
  }

  return (
    <Layout>
      <Container className="page-container article-container">
        <Row className="justify-content-between align-items-center mb-4">
          <Col md={6} className="page-title m-0">
            <span>{t("article.article")}</span>
          </Col>
          <Col md={6} xs={12}>
            <Row className="justify-content-between">
              <Col md={12} className="d-flex justify-content-end search-header">
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("article.enterKeyword")}
                />
                <Button type="submit" onClick={e => onSearch(e)}>
                  {/* {t("search")} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Title label={t("article.article")} />
        <Form className="mini-news-container ">
          <Form.Row>
            <Form.Control
              onChange={e => setSearchTextTemp(e.target.value)}
              placeholder={t("article.enterKeyword")}
            />
            <Button type="submit" onClick={e => onSearch(e)}>
              {t("search")}
            </Button>
          </Form.Row>
        </Form> */}
        <Row className="mini-news-container ">
          <Col md={9} className="article-list">
            <Row>
              {article && article.length > 0 && roundPagesCount > 0 ? (
                article
                  .filter(item =>
                    item.title.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map(item => (
                    <Link
                      key={item?.id}
                      className="individual-article"
                      href={`/berita/artikel/${item?.slug}`}
                    >
                      <ListArticle
                        id={item?.id}
                        title={item?.title}
                        desc={item?.content}
                        date={item?.date}
                        alt={item?.title}
                        thumbnail={item?.article?.thumbnail?.sourceUrl}
                      />
                    </Link>
                  ))
              ) : (
                <div className="d-flex justify-content-center">
                  {t("noData")}
                </div>
              )}
              {article && article.length > 0 && roundPagesCount > 0 && (
                // <div className="d-flex justify-content-center mt-4">
                //   <Pagination
                //     pagesCount={roundPagesCount}
                //     currentPage={currentPage}
                //     handlePageClick={handlePageClick}
                //     handlePreviousClick={handlePreviousClick}
                //     handleNextClick={handleNextClick}
                //   />
                // </div>
                <div
                  className={`${classes.root} d-flex justify-content-center mt-4`}
                >
                  <Pagination
                    count={roundPagesCount}
                    page={currentPage}
                    onChange={handlePageClick}
                    shape="rounded"
                    color="primary"
                  />
                </div>
              )}
            </Row>
          </Col>
          <Col md={3} className="d-flex justify-content-end">
            {dataIklanGoldB?.length > 0 &&
            inRangeDate(
              dataIklanGoldB[0].iklan.tanggalJangkaIklanAwal,
              dataIklanGoldB[0].iklan.tanggalJangkaIklanAkhir
            ) &&
            dataIklanGoldB[0].iklan.uploadGambarIklanGoldB ? (
              <VerticalAds
                width={280}
                height={450}
                title="B. Gold AD SPACE"
                img_url={
                  dataIklanGoldB[0].iklan.uploadGambarIklanGoldB.sourceUrl
                }
              />
            ) : (
              <VerticalAds width={280} height={450} title="B. Gold AD SPACE" />
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default withTrans(Artikel)

export const query = graphql`
  {
    allWpArtikel(sort: { fields: date, order: DESC }) {
      nodes {
        date(formatString: "MMMM DD, YYYY")
        language {
          code
        }
        article {
          thumbnail {
            sourceUrl
          }
        }
        id
        slug
        uri
        title
        content
        link
      }
    }
    allWpIklanGks(sort: { fields: date, order: DESC }) {
      nodes {
        title
        iklan {
          slotIklan
          deskripsiIklan
          letakIklan {
            bronzeAdSpace
            goldA
            premium
            silverAdSpace
            goldB
          }
          uploadGambarIklanBronzeAdSpace {
            sourceUrl
          }
          uploadGambarIklanGoldA {
            sourceUrl
          }
          uploadGambarIklanGoldB {
            sourceUrl
          }
          uploadGambarIklanPremium {
            sourceUrl
          }
          uploadGambarIklanSilverAdSpace {
            sourceUrl
          }
          tanggalJangkaIklanAkhir
          tanggalJangkaIklanAwal
        }
        date
        language {
          code
        }
      }
    }
  }
`
