import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const Index = ({ title, desc, date, alt, thumbnail }) => (
  <Container className="page-container article">
    <Row>
      <Col md={7} className="article-content">
        <h3 className="title">{title}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: desc }}
          className="article-overflow"
        />
        <span className="date">{date}</span>
      </Col>
      <Col md={5} className="remove-padding">
        <img
          src={
            thumbnail
              ? thumbnail
              : "../../../../images/Artikel_default-image.png"
          }
          alt={alt}
          height={185}
          // width={380}
          width="100%"
          className="article-img"
        />
      </Col>
    </Row>
  </Container>
)

export default Index
